<template>
  <validation-provider :name="id" :rules="computedValidationRules" mode="eager" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <div :class="fieldCSSClass">
        <p-check :id="id" class="p-switch p-fill no-margins" color="success" v-model="input" :required="required">&nbsp;</p-check>
      </div>
      <label :for="id" :class="labelCSSClass">
        <span class="form-control-plaintext"><slot name="label">{{ inputLabel }}</slot></span>
        <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
      </label>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class Checkbox extends mixins<GenericInput<boolean>>(GenericInput) {

  inputLabel = "";
  inputPlaceholder = "";

  labelCSSClass = "col col-form-label"
  fieldCSSClass = "col-auto";

  get rowCSSClass() {
    return {"form-group":true, "row":true, "no-gutters":true};
  }

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : "";
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : "";
  }

  
  
}
</script>