<template>
  
    <validation-provider name="Phone" mode="eager" :rules="intlTelValidationRule" v-slot="{ errors, classes, validate }" slim>
      <div :class="{'form-group':true, ...classes}">
        <label v-if="listPhoneTypes.length == 1" :for="phoneFieldId">{{getPhoneLabel(listPhoneTypes[0])}}</label>
        <div v-if="listPhoneTypes.length > 1" class="col-lg-3">
          <v-select :options="listPhoneTypes" :getOptionLabel="getPhoneLabel" :id="'phone_type'" :placeholder="$t('phones.form.type_placeholder')" @keypress.enter.native.prevent="" v-model="form.type"/>
        </div>
        <input v-if="listPhoneTypes.length == 1" type="tel" :id="phoneFieldId" class="form-control" v-on:input="onPhoneUpdate($event) || validate($event)" :required="required" />
        <div v-if="listPhoneTypes.length > 1" class="col-lg-9">
          <input type="tel" :id="phoneFieldId" class="form-control" v-on:input="onPhoneUpdate" />
        </div>
        <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </validation-provider>

</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import { setTimeout } from 'timers';
import { extend } from "vee-validate";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';

@Component({
  components: { }
})
export default class Phone extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly phone!: any | undefined

  @Prop({
    type: String,
    required: false
  }) readonly label!: string

  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) readonly required!: boolean

  form:any = { // The form contains the updated phone
    type: (this.phone && this.phone.type) ? this.phone.type :"",
    number: (this.phone && this.phone.number) ? this.phone.number :"",
  }; 

  listPhoneTypes:any[] = (this.phone && this.phone.type) ? [this.phone.type] :[];

  intlTel:intlTelInput.Plugin|null = null;

  getPhoneLabel(typeCode:string) {
    if(this.label) {
      return this.label;
    }
    else if(this.$te('phones.types.'+typeCode)) {
        return this.$t('phones.types.'+typeCode);
    }
    return typeCode;
  }

  get phoneFieldId() {
    if(this.listPhoneTypes.length == 1) {
      return 'phone_'+this.listPhoneTypes[0];
    }
    else {
      return 'phone_number';
    }
  }

  created() {
    this.updateListPhoneTypes();
    this.addPhoneValidation();
  }

  mounted() {
    // We load the intlTel component
    var intlTel = intlTelInput(document.querySelector("#"+this.phoneFieldId) as Element, {
        preferredCountries : ['fr'],
        allowDropdown : true,
        // @ts-ignore
        customContainer : "flag-left"
      });
    if(this.phone.number != "") {
      intlTel.setNumber(this.phone.number);
    }
    this.intlTel = intlTel;
  }

  beforeDestroy() {
    if(this.intlTel) {
      this.intlTel.destroy();
    }
  }

  updateListPhoneTypes() {
    if(this.listPhoneTypes.length != 1) {
      // We need to get the list of available companies for the current logged in user
      var options:api.ApiVueOptions =  {
        app: this
      }
      api.getAPI('/api/utils/listPhoneTypes', options).then((response:any) => {
        if(response.phoneTypes) {  
          this.listPhoneTypes = response.phoneTypes;
        }
      });
    }
  }

  get intlTelValidationRule() {
    return {
      intlTel: { 
        field: this.intlTel
      }
    }
  }

  addPhoneValidation() {
    var componentInstance = this;
    extend('intlTel',{
      params: ['field'],
      validate(phoneNumber, params):Promise<boolean|string> {

        // @ts-ignore
        var intlTel = params.field;

        if(componentInstance.form.number != "" &&
            intlTel != null && 
            !intlTel.isValidNumber()) {
          return Promise.resolve(componentInstance.$t("phones.errors.invalid") as string);
        }
        
        return Promise.resolve(true);
      }
    });
  }

  onPhoneUpdate(event:Event) {
    //@ts-ignore
    var number = event.target.value;
    if(this.intlTel) {
      number = this.intlTel.getNumber();
    } 
    this.form.number = number;
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListPhoneTypes();
  }

  @Watch('form', { deep: true })
  onFormChanged(val: any, oldVal: any) {
    this.$emit('update:phone', val);
  }
  
}
</script>