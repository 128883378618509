<template>
  <div id="register" class="middle-box text-center animated fadeInDown">
    <div>

      <div>
        <img src="@public/img/eurofiscalis/logo.png" alt="Eurofiscalis">
      </div>

      <h3 v-if="$te('login.sub-welcome') && $t('login.sub-welcome') != ''">{{ $t('login.welcome') }}</h3>
      <p v-if="$te('login.sub-title') && $t('login.sub-title') != ''">
        {{ $t('login.sub-title') }}
      </p>

      <Messages :messages="errors" type="error"></Messages>

      <div class="ibox-content m-t">

        <validation-observer v-slot="{ invalid }" slim>
          <form id="register-form" name="register-form" role="form" action="#" method="post" @submit="onRegisterFormSubmit" ref="registerForm">
            
            <h3>{{ $t('company.info.title') }}</h3>

            <validation-provider name="Company name" mode="eager" v-slot="{ errors, classes }" slim>
              <div :class="{'form-group':true, ...classes}">
                  <label for="companyName">{{$t('company.info.name')}}</label>
                  <input type="text" class="form-control" :placeholder="$t('company.info.name_placeholder')" required="required" autocomplete="companyName" name="companyName" id="companyName" v-model="form.company.name">
                  <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
              </div>
            </validation-provider>

            <UpdateAddress v-bind:address.sync="form.company.address" :options="addressOptions"></UpdateAddress>

            <validation-provider name="VAT number" mode="eager" :skipIfEmpty="false" :rules="vatNumberValidationRules" v-slot="{ errors, classes, valid }" slim>
              <div :class="{'form-group':true, ...classes}">
                <label for="vatNumber">{{$t('company.info.vatNumber')}}</label>
                <input type="text" class="form-control" :placeholder="$t('company.info.vatNumber_placeholder')" id="vatNumber" name="vatNumber" autocomplete="vatNumber" v-model="form.company.vatNumber">
                <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
                <span class="control-label form-text m-b-none" v-if="valid">{{ vatDetails }}</span>
              </div>
            </validation-provider>

            <div class="hr-line-dashed"></div>
            <h3>{{ $t('profile.info.title') }}</h3>

            <Civility v-bind:value.sync="form.profile.civility" required />

            <FirstName v-bind:value.sync="form.profile.firstName" required />

            <LastName v-bind:value.sync="form.profile.lastName" required />

            <validation-provider name="email" mode="eager" v-slot="{ errors, classes }" slim>
              <div :class="{'form-group':true, ...classes}">
                <label for="email">{{ $t('profile.info.email') }}</label>
                <input id="email" type="email" :placeholder="$t('profile.info.email_placeholder')" class="form-control" v-model="form.profile.email" autocomplete="on" required/>
                <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
              </div>
            </validation-provider>

            <validation-provider name="password" mode="eager" v-slot="{ errors, classes }" slim>
              <div :class="{'form-group':true, ...classes}">
                <label for="password">{{ $t('profile.info.password') }}</label>
                <input id="password" type="password" :placeholder="$t('profile.info.password_placeholder')" autocomplete="new-password" class="form-control" v-model="form.profile.password" required/>
                <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
              </div>
            </validation-provider>

            <validation-provider name="confirmPassword" mode="eager" v-slot="{ errors, classes }" slim>
              <div :class="{'form-group':true, ...classes}">
                <label for="confirmPassword">{{ $t('profile.info.confirmPassword') }}</label>
                <input id="confirmPassword" type="password" :placeholder="$t('profile.info.confirmPassword_placeholder')" autocomplete="new-password" class="form-control" v-model="form.profile.confirmPassword" required/>
              </div>
              <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
            </validation-provider>

            <validation-provider name="Position" mode="eager" v-slot="{ errors, classes }" slim>
              <div :class="{'form-group':true, ...classes}">
                <label for="position">{{$t('profile.info.position')}}</label>
                <select id="position" class="form-control" v-model="form.profile.position" required>
                  <option value="">{{$t('profile.info.position_select')}}</option>
                  <option v-for="(position, index) in positions" :key="index" :value="position">{{$t('profile.positions.'+position)}}</option>
                </select>
                <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            
            <Phone v-bind:phone.sync="form.profile.phone" :label="$t('eurofiscalis_workers.register.form.phone')" required />

            <div class="hr-line-dashed"></div>
            <h3>{{ $t('eurofiscalis_workers.register.subscription') }}</h3>

            <validation-provider v-if="isPromoCode" name="promoCode" mode="eager" rules="promoCode" :skipIfEmpty="false" v-slot="{ errors, classes }" slim>
              <div :class="{'form-group':true, ...classes}">
                <label for="promoCode">{{ $t('eurofiscalis_workers.register.form.promoCode') }}</label>
                <input id="promoCode" type="text" :placeholder="$t('eurofiscalis_workers.register.form.promoCode_placeholder')" class="form-control" v-model="form.promoCode" />
                <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
              </div>

              <b-modal ref="confirmEmptyPromoCodeModal" 
                  hide-header
                  @ok="confirmRegisterWithoutPromo"
                  :ok-title="$t('eurofiscalis_workers.register.no-promoCode-modal.ok')"
                  :cancel-title="$t('eurofiscalis_workers.register.no-promoCode-modal.cancel')">
                {{$t('eurofiscalis_workers.register.no-promoCode-modal.title')}}
              </b-modal>
            </validation-provider>

            <validation-provider v-if="showNumberOfWorkers" name="NumberOfWorkers" mode="eager" v-slot="{ errors, classes }" slim>
              <div :class="{'form-group':true, ...classes}">
                <label for="numberOfWorkers">{{$t('eurofiscalis_workers.register.form.numberOfWorkers')}}</label>
                <select id="numberOfWorkersnumberOfWorkers" class="form-control" v-model="form.numberOfWorkers" required>
                  <option value="">{{$t('eurofiscalis_workers.register.form.numberOfWorkers_select')}}</option>
                  <option v-for="index in maxIndividualOfWorkers" :key="index" :value="index">{{index}}</option>
                  <option value="more">{{$t('eurofiscalis_workers.register.form.numberOfWorkers_more',[maxIndividualOfWorkers+1])}}</option>
                </select>
                <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
              </div>
            </validation-provider>

            <validation-provider name="RepresentativeCountries" ref="representativeCountriesValidator" mode="eager" :skipIfEmpty="false" rules="required" v-slot="{ errors, classes }" slim>
              <div :class="{'form-group':true, ...classes}">
                <label for="representativeCountries">{{$t('eurofiscalis_workers.register.form.representativeCountries')}}</label>
                <v-select ref="listRepresentativeCountries" id="representativeCountries" :placeholder="$t('eurofiscalis_workers.register.form.representativeCountries_placeholder')" multiple v-model="form.representativeCountries" :options="listRepresentativeCountries" :reduce="country => country.code">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      :required="form.representativeCountries.length == 0"
                      v-bind="attributes"
                      v-on="events"
                      autocomplete="representative_country"
                    />
                  </template>
                </v-select>
                <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
              </div>
            </validation-provider>

            <div class="hr-line-dashed"></div>

            <validation-provider v-if="allowedMOPs.length > 1" name="MethodOfPayment" mode="eager" v-slot="{ errors, classes }" slim>
              <div :class="{'form-group':true, ...classes}">
                <label for="methodOfPayment">{{$t('eurofiscalis_workers.register.form.methodOfPayment')}}</label>
                <select id="methodOfPayment" class="form-control" v-model="form.mop" required>
                  <option value="">{{$t('eurofiscalis_workers.register.form.methodOfPayment_select')}}</option>
                  <option v-for="index in allowedMOPs" :key="index" :value="index">{{$t('eurofiscalis_workers.register.form.mops.'+index)}}</option>
                </select>
                <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
              </div>
            </validation-provider>

            <Checkbox v-bind:value.sync="form.conditions" id="conditions" required>
              <template v-slot:label>
                <i18n path="eurofiscalis_workers.register.conditions.label" tag="span">
                  <a href="javascript:void(0)" @click="showConditionsModal()">{{ $t('eurofiscalis_workers.register.conditions.link') }}</a>
                </i18n>
              </template>
            </Checkbox>

            <ConditionsModal ref="conditionsModal" />

            <button :disabled="invalid" type="submit" class="btn btn-primary block full-width ladda-button" data-style="zoom-in">{{ $t('profile.info.register') }}</button>

          </form>
        </validation-observer>
      </div>

      <p class="m-t">
          <small>{{ $t("footer.copyright", [year]) }}</small>
      </p>

    </div>
  </div>

</template>

<style scoped>
  #register-form {
    text-align: left;
  }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import Messages from '../../../panels/Messages.vue';
import Phone from '../../../panels/contact/phones/Phone.vue';
import Civility from '../../../panels/input/Civility.vue';
import FirstName from '../../../panels/input/FirstName.vue';
import LastName from '../../../panels/input/LastName.vue';
import Checkbox from '../../../panels/input/Checkbox.vue';
import UpdateAddress from '../../../panels/UpdateAddress.vue';
import ConditionsModal from '../../../panels/eurofiscalis/workers/ConditionsModal.vue';
import { messagesTypes, Message } from '@fwk-client/store/types';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { extend } from "vee-validate";

import { types as eurofiscalisTypes } from '../../../../store/eurofiscalis';
import { positions, nbOfEmployees, maxIndividualOfWorkers } from '../../../../types/common';

@Component({
  components : {
    UpdateAddress,
    Messages,
    Phone,
    Civility,
    FirstName,
    LastName,
    Checkbox,
    ConditionsModal
  }
})
export default class Register extends mixins(GenericPage) {

  isPromoCode = true;
  isRegisterWithoutPromoConfirmed = false;
  showNumberOfWorkers = false;

  errors:Message[] = [];
  
  year = (new Date()).getFullYear();

  positions = positions;
  nbOfEmployees = nbOfEmployees;
  maxIndividualOfWorkers = maxIndividualOfWorkers;

  addressOptions = {
    showName: false,
    labelAsColumn: false,
    nbAddressLines: 2
  }

  listRepresentativeCountries:any[] = [];
  allowedMOPs:string[] = [];

  vatDetails:any = "";

  vatValidation:boolean = this.$store.state.server.configuration.app.vatValidation;

  emptyForm:any = {
    company: {
      name:"",
      vatNumber:"",
      address:{
        addressLines:[], 
        postalCode:"", 
        city:"", 
        countryCode:""
      },
      size:""
    },
    profile: {
      civility:"",
      firstName:"",
      lastName:"",
      email:"",
      password:"",
      confirmPassword:"",
      position:"",
      phone:{
        type:"BUSINESS",
        number:""
      }
    },
    promoCode:"",
    representativeCountries:[],
    numberOfWorkers:"",
    conditions: false,
    mop:""
  }
  
  testForm:any = {
    company: {
      name:"Test Company",
      vatNumber:"FR21834212904",
      address:{
        addressLines:["184 Avenue de Gairaut"], 
        postalCode:"06100", 
        city:"Nice", 
        countryCode:"FR"
      },
      size:50
    },
    profile: {
      civility:"MR",
      firstName:"Nicolas",
      lastName:"Igot",
      email:"test-account@gmail.com",
      password:"test1234",
      confirmPassword:"test1234",
      position:"PRESIDENT",
      phone:{
        type:"BUSINESS",
        number:"+33608406362"
      }
    },
    promoCode:"",
    representativeCountries:["FR","IT"],
    numberOfWorkers:"6",
    conditions: false,
    mop:""
  }

  form:any = {
    ...this.emptyForm
  }

  laddaSubmit:Ladda.LaddaButton|null = null;

  get vatNumberValidationRules() {
    if(this.vatValidation) {
      var countryCode = null;
      if(this.form && this.form.company && this.form.company.address) {
        countryCode = this.form.company.address.countryCode;
      }
      return {
        vatNumber: { 
          countryCode: countryCode
        }
      }
    }
    else {
      return {
        "required" : {}
      }
    }
  }

  created() {
    // We get the messages from store
    var messages = this.$store.getters['messages/' + messagesTypes.getters.GET_MESSAGES];
    if(messages.length > 0) {
      // We update the list of errors
      this.errors = messages;
    }
    
    this.addValidation();

    // We update the list of representative countries
    this.updateListRepresentativeCountries();
    this.updateListAllowedMops();
  }

  mounted() {
    // We clear the messages from store
    this.$store.commit('messages/'+messagesTypes.mutations.CLEAR_MESSAGES);

    var button:HTMLButtonElement|null = document.querySelector( 'form[name=register-form] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  updateListRepresentativeCountries() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/eurofiscalis/workers/listRepresentativeCountries', options).then((response:any) => {
      if(response.countries) {  
        this.listRepresentativeCountries = response.countries;
        var _self = this;
        if(_self.form.representativeCountries && _self.form.representativeCountries.length > 0) {
          // We get the value from representativeCountries
          var selected = this.listRepresentativeCountries.filter(function(representativeCountry) {
            return _self.form.representativeCountries.map((country:any) => {
              return country.code;
            }).indexOf(representativeCountry.code) > -1;
          });
          if(selected && selected.length > 0) {
            // @ts-ignore
            _self.$refs.listRepresentativeCountries.updateValue(selected);
          }
        }
      }
    });
  }

  updateListAllowedMops() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/eurofiscalis/workers/listAllowedMops', options).then((response:any) => {
      if(response.mops) {  
        this.allowedMOPs = response.mops;
      }
    });
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListRepresentativeCountries();
  }

  @Watch('form.representativeCountries')
  onInputChange() {
    // @ts-ignore
    this.$refs.representativeCountriesValidator.validate();
  }

  addValidation() {
    var componentInstance = this;
    
    if(this.vatValidation) {
      extend('vatNumber',{
        params: ['countryCode'],
        validate(vatNumber, params):Promise<boolean|string> {

          componentInstance.vatDetails = "";

          // @ts-ignore
          var countryCode = params.countryCode;
          if(!countryCode) {
            return Promise.resolve(componentInstance.$t("company.error.countryCode-required") as string);
          }

          if(!vatNumber || vatNumber == "") {
            return Promise.resolve(componentInstance.$t("company.error.vat-required") as string);
          }
          
          var input = {
              "vatNumber" : vatNumber,
              "countryCode" : countryCode
            }

          var options:api.ApiVueOptions =  {
            app: componentInstance
          }

          return new Promise((resolve,reject) => {
            api.postAPI('/api/utils/companies/validateVAT', input, options).then((response:any) => {
              if(response.validated) {
                componentInstance.vatDetails = response.name;  
                return resolve(true);
              }
              else {
                return resolve(componentInstance.$t("company.error.vat-invalid") as string);
              }
            })
            .catch((error) => {
              return resolve(error);
            });
          })
        }
      });
    }

    extend('promoCode',{
      validate(promoCode):Promise<boolean|string> {

        if(!promoCode || promoCode == "") {
          componentInstance.showNumberOfWorkers = false;
          return Promise.resolve(true);
        }
 
        var input = {
          "promoCode" : promoCode
        }

        var options:api.ApiVueOptions =  {
          app: componentInstance
        }

        return new Promise((resolve,reject) => {
          api.postAPI('/api/eurofiscalis/workers/validatePromoCode', input, options).then((response:any) => {
            if(response.amounts) {
              componentInstance.showNumberOfWorkers = response.amounts.isNumberOfWorkersNeeded;  
              
            }
            return resolve(true);
          })
          .catch((error) => {
            return resolve(error);
          });
        })
      }
    });
  }

  onRegisterFormSubmit(evt:Event) {
    evt.preventDefault();

    // We check if there is a promoCode and if not if we are allowed
    if(this.isPromoCode && this.form.promoCode == "" && 
        !this.isRegisterWithoutPromoConfirmed) {
      // @ts-ignore
      this.$refs.confirmEmptyPromoCodeModal.show();
      return;
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/eurofiscalis/workers/register', this.form, options).then((response:any) => {

      if(response.paymentRedirectionForm) {
        // We update the eurofiscalis store
        this.$store.commit('eurofiscalis/' + eurofiscalisTypes.mutations.WORKERS_REGISTER_UPDATE_PAYMENT, response.paymentRedirectionForm);
        // We switch to the payment page
        this.$router.push('./payment').catch(err => {});
      }
      else if(response.mop && response.mop == "TRANSFER") {
        // We update the eurofiscalis store
        this.$store.commit('eurofiscalis/' + eurofiscalisTypes.mutations.WORKERS_REGISTER_UPDATE_PAYMENT, {
          mop : response.mop,
          requestId : response.requestId
        });
        // We switch to the bank-transfer page
        this.$router.push('./bank-transfer').catch(err => {});
      }

      this.laddaSubmit!.stop();
    });
  }

  confirmRegisterWithoutPromo() {
    // We update the flag to allow register without promo code
    this.isRegisterWithoutPromoConfirmed = true;
    // We submit the form
    (this.$refs.registerForm as HTMLFormElement).dispatchEvent(new Event('submit'));
  }

  showConditionsModal() {
    // @ts-ignore
    this.$refs.conditionsModal.show();
  }
  
}
</script>