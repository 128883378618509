<template>
  <section>
    <div v-if="computedOptions.showName" :class="rowCSSClass">
        <label for="updateAdressName" :class="labelCSSClass">{{ $t('address.form.name') }}</label>
        <div :class="fieldCSSClass">
          <input id="updateAdressName" type="text" :placeholder="$t('address.form.name_placeholder')" class="form-control" v-model="form.name" />
        </div>
    </div>
    <div v-for="index in nbAddressLines" :key="index" :class="rowCSSClass">
        <label v-if="index==1" :for="'updateAddressLine_'+index" :class="labelCSSClass">{{ $t('address.form.addressLine') }}</label>
        <div v-else :class="labelCSSClass"></div>
        <div :class="fieldCSSClass">
          <vue-autosuggest v-if="addressAutoComplete && index==1"
            v-model="form.addressLines[0]"
            :suggestions="addressSuggestions"
            :component-attr-id-autosuggest="'address-autosuggest-'+index"
            :get-suggestion-value="getAddressSuggestionValue"
            :render-suggestion="renderAddressSuggestionValue"
            @selected="onAddressSuggestionSelected"
            @input="onAddressInputChange($event)"
            :input-props="{id: 'updateAddressLine_'+index, placeholder:$t('address.form.addressLine_placeholder'), class:'autosuggest__input form-control', autocomplete:'new-company-address'}"
          >
          </vue-autosuggest>
          <input v-else :id="'updateAddressLine_'+index" type="text" :placeholder="$t('address.form.addressLine_placeholder')" class="form-control" v-model="form.addressLines[index - 1]" />
        </div>
    </div>
    <div :class="rowCSSClass">
        <label for="updatePostalCode" :class="labelCSSClass">{{ $t('address.form.postalCode') }}</label>
        <div :class="fieldCSSClass">
          <input id="updatePostalCode" type="text" :placeholder="$t('address.form.postalCode_placeholder')" class="form-control" v-model="form.postalCode" />
        </div>
    </div>
    <div :class="rowCSSClass">
        <label for="updateCity" :class="labelCSSClass">{{ $t('address.form.city') }}</label>
        <div :class="fieldCSSClass">
          <input id="updateCity" type="text" :placeholder="$t('address.form.city_placeholder')" class="form-control" v-model="form.city" />
        </div>
    </div>
    <div :class="rowCSSClass">
        <label for="updateCountry" :class="labelCSSClass">{{ $t('address.form.country') }}</label>
        <div :class="fieldCSSClass">
          <v-select ref="listCountries" :options="listCountries" :reduce="country => country.code" label="label" id="updateCountry" :placeholder="$t('address.form.country_select')" @keypress.enter.native.prevent="" v-model="form.countryCode"/>
        </div>
    </div>
  </section>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';

@Component({
  components: { }
})
export default class UpdateAddress extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly address!: any | undefined

  @Prop({
    type: Object,
    required: false,
  }) readonly options!: any | undefined

  computedOptions:any = {
    showName: true,
    labelAsColumn: true,
    nbAddressLines: 3,
    ...this.options
  };

  addressAutoComplete:boolean = this.$store.state.server.configuration.app.addressAutoComplete;

  nbAddressLines:number = this.address && this.address.addressLines ? Math.max(this.address.addressLines.length, this.computedOptions.nbAddressLines) : this.computedOptions.nbAddressLines;

  listCountries:any[] = [];

  addressSuggestions:any = [];

  form = this.address ? {
    name: this.address.name,
    addressLines : this.address.addressLines,
    postalCode : this.address.postalCode,
    city : this.address.city,
    countryCode : this.address.countryCode.toUpperCase()
    } : {
    name : '',
    addressLines : this.defaultAddressLines,
    postalCode : '',
    city : '',
    countryCode : ''
  };

  get defaultAddressLines():string[] {
    var lines = [];
    for(var i = 0 ; i < this.computedOptions.nbAddressLines ; i++) {
      lines.push('');
    }
    return lines;
  }

  labelCSSClass = this.computedOptions.labelAsColumn ? "col-lg-3 col-form-label" : null
  fieldCSSClass = this.computedOptions.labelAsColumn ? "col-lg-9" : null;
  rowCSSClass = this.computedOptions.labelAsColumn ? "form-group row" : "form-group";

  onAddressInputChange(text:string) {
    // We need to get the list of addresses based on what has been entered by the end user
    if(text.length < 4) {
      this.addressSuggestions = [];  
      return;
    }

    var options:api.ApiVueOptions =  {
      app: this
    }
    var input:any = {
      address: { ...this.form }
    }
    api.postAPI('/api/utils/getAddressSuggestions', input, options).then((response:any) => {
      if(response.suggestions) {  
        this.addressSuggestions = [{data:response.suggestions}]; 
      }
      else {
        this.addressSuggestions = [];
      }
    });    
  }

  getAddressSuggestionValue(suggestion:any) {
    return suggestion.item.street;
  }

  onAddressSuggestionSelected(suggestion:any) {
    var place_id = suggestion.item.place_id;

    var options:api.ApiVueOptions =  {
      app: this
    }
    var input:any = {
      place_id: place_id
    }
    api.postAPI('/api/utils/getAddressDetails', input, options).then((response:any) => {
      if(response.details) {  
        this.form.addressLines = response.details.addressLines;
        this.form.postalCode = response.details.postalCode;
        this.form.city = response.details.city;
        this.form.countryCode = response.details.countryCode.toUpperCase();
      }
    }); 
  }

  renderAddressSuggestionValue(suggestion:any) {
    var substrings = suggestion.item.matched_substrings;
    var slices = [];
    var text = suggestion.item.label;
    var index = 0;
    for(var match of substrings) {
      if(match.offset > index) {
        slices.push(text.slice(index, match.offset));
      }
      var slice = text.slice(match.offset, match.offset+match.length);
      slices.push(this.$createElement('b', slice));
      index = match.offset+match.length;
    }
    if(index < text.length) {
      slices.push(text.slice(index));
    }
    
    
    return this.$createElement('span',slices);
  }

  created() {
    this.updateListCountries();
  }

  updateListCountries() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/utils/listEUCountries', options).then((response:any) => {
      if(response.countries) {  
        this.listCountries = response.countries;
        var _self = this;
        if(_self.form.countryCode && _self.form.countryCode != "") {
          // We get the value from countryCode
          var selected = this.listCountries.find(function(element) {
            return element.code == _self.form.countryCode;
          });
          if(selected) {
            // @ts-ignore
            _self.$refs.listCountries.updateValue(selected);
          }
        }
      }
    });
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListCountries();
  }

  @Watch('form', { deep: true })
  onFormChanged(val: any, oldVal: any) { 
    this.$emit('update:address', val);
  }
  
}
</script>